import request from '@/utils/request';
// 顶部切换分类
export const changeCategory = (data) => request({
  url: '/api/question/switch_category',
  method: 'POST',
  data
});
// 获取练习导航
export const navigation= (data) => request({
  url: '/api/question/navigation',
  method: 'POST',
  data
});
// 获取三级导航
export const threeCategory= (data) => request({
  url: '/api/question/three_category',
  method: 'POST',
  data
});
// 获取题库列表信息
export const questionList= (data) => request({
  url: '/api/question/list',
  method: 'POST',
  data
});
// 开始答题
export const examination = (data) => request({
  url: '/api/question/answer',
  method: 'POST',
  data
});
// 提交试卷
export const handExam = (data) => request({
  url: '/api/question/hand_exam',
  method: 'POST',
  data
});
// 试卷解析
export const analysis = (data) => request({
  url: '/api/question/analysis',
  method: 'POST',
  data
});