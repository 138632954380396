<template>
  <div class="bgc" v-if="test">
    <div class="w">
      <div class="bgc-kong"></div>
      <div class="exam">
        <div class="examCont">
          <div class="examTop1"></div>
          <div class="examTop flex3">
            <!-- <div class="goBack" @click="goback">
              <img src="../../assets/img/tiku/icon_fanhui.png" alt />
              返回
            </div>-->
            <div class="examName">{{exam.examsubject}}</div>
            <div class="step">
              <div>正确率：{{exam.rate}}%</div>
              <div>共{{exam.questionnum}}道试题</div>
              <!-- <div>总分：{{exam.total}}分</div>
              <div>及格：{{exam.passing}}分</div>-->
            </div>
          </div>
          <div class="examType">
            <!-- 试题列表 -->
            <div
              class="examList"
              :class="`examList${item.id}`"
              :ref="`examList${item.id}`"
              v-for="(item, i) in examList"
              :key="i"
            >
              <div class="topic flex">
                <div v-if="item.quetiontype == 4" class="status">单选题</div>
                <div v-if="item.quetiontype == 1" class="status status1">多选题</div>
                <div v-if="item.quetiontype == 5" class="status status2">判断题</div>
                <div v-if="item.quetiontype== 2" class="status">填空题</div>
                <div v-if="item.quetiontype == 3" class="status status1">简答题</div>
                <div v-if="item.quetiontype== 6" class="status status2">应用题</div>
                <div v-if="item.quetiontype== 7" class="status status2">作文</div>
                <div v-if="item.quetiontype== 8" class="status status2">辨析题</div>
                <div class="index">{{i+1}}.</div>
                <div class="text" v-html="item.subject"></div>
              </div>
              <div class="option" v-if="item.quetiontype!= 6">
                <!-- 单选题目 -->
                <el-radio-group disabled v-model="item.myanswer" v-if="item.quetiontype== 4">
                  <div class="choose" v-for="(item1, index1) in item.option" :key="index1">
                    <el-radio :label="item1.option">
                      <span v-html="item1.content"></span>
                    </el-radio>
                  </div>
                </el-radio-group>
                <!-- 多选题目 -->
                <el-checkbox-group
                  disabled
                  v-model="item.myanswer"
                  v-if="item.quetiontype== 1&&test"
                >
                  <el-checkbox
                    class="choose"
                    :label="item1.option"
                    v-for="(item1, index1) in item.option"
                    :key="index1"
                  >
                    <span v-html="item1.content"></span>
                  </el-checkbox>
                </el-checkbox-group>
                <!-- 判断题 -->
                <el-radio-group disabled v-model="item.myanswer" v-if="item.quetiontype == 5">
                  <div class="choose" v-for="(item1, index1) in item.option" :key="index1">
                    <el-radio :label="item1">
                      <span v-html="item1"></span>
                    </el-radio>
                  </div>
                </el-radio-group>
                <!-- 填空题 -->
                <!-- <textarea class="response" v-if="item.quetiontype == 2 " placeholder="请输入内容" /> -->
                <el-input
                  class="response"
                  type="textarea"
                  readonly
                  v-model="item.myanswer"
                  v-if="item.quetiontype == 2 || item.quetiontype == 3||item.quetiontype == 7 ||item.quetiontype == 8"
                  :autosize="{ minRows: 2}"
                  placeholder="无"
                ></el-input>
                <div class="contrast">
                  <span>正确答案:</span>
				  <span v-html="item.answer"></span>
                </div>
                <div
                  class="resultBtn_box"
                  v-if="item.quetiontype==4 || item.quetiontype==1 ||item.quetiontype==5"
                >
                  <div class="resultBtn resultBtn1" v-if="item.judge==0">答错了</div>
                  <div class="resultBtn" v-if="item.judge==1">答对了</div>
                </div>
                <div class="analysis">
                  <span>解析：</span>
				  <span v-html="item.analysis"></span>
                </div>
              </div>
              <div v-else class="option option1" v-for="(item1, index1) in item.son" :key="index1">
                <div class="topic1 topic">
                  <span class="index">({{index1+1}})</span>
                  <span v-html="item1.subject"></span>
                  <span class="warp" v-if="item1.quetiontype == 4">(单选)</span>
                  <span class="warp" v-if="item1.quetiontype == 1">(多选)</span>
                  <span class="warp" v-if="item1.quetiontype == 5">(判断)</span>
                  <span class="warp" v-if="item1.quetiontype== 2">(填空)</span>
                  <span class="warp" v-if="item1.quetiontype == 3">(简答)</span>
                  <span class="warp" v-if="item1.quetiontype== 7">(作文)</span>
                  <span class="warp" v-if="item1.quetiontype== 8">(辨析)</span>
                </div>
                <el-radio-group disabled v-model="item1.myanswer" v-if="item1.quetiontype== 4">
                  <div class="choose" v-for="(item2, index2) in item1.option" :key="index2">
                    <el-radio :label="item2.option">
                      <span v-html="item2.content"></span>
                    </el-radio>
                  </div>
                </el-radio-group>
                <el-checkbox-group
                  disabled
                  v-model="item1.myanswer"
                  v-if="item1.quetiontype== 1&&test"
                >
                  <el-checkbox
                    class="choose"
                    :label="item2.option"
                    v-for="(item2, index2) in item1.option"
                    :key="index2"
                  >
                    <span v-html="item2.content"></span>
                  </el-checkbox>
                </el-checkbox-group>
                <el-radio-group disabled v-model="item1.myanswer" v-if="item1.quetiontype == 5">
                  <div class="choose" v-for="(item2, index2) in item1.option" :key="index2">
                    <el-radio :label="item2">
                      <span v-html="item2"></span>
                    </el-radio>
                  </div>
                </el-radio-group>
                <el-input
                  class="response"
                  type="textarea"
                  v-model="item1.myanswer"
                  v-if="item1.quetiontype == 2 || item1.quetiontype == 3||item1.quetiontype == 7 ||item1.quetiontype == 8"
                  :autosize="{ minRows: 2}"
                  placeholder="无"
                  readonly
                ></el-input>
                <div class="contrast">
                  <span>正确答案:</span>
                  <span>{{item1.answer}}</span>
                </div>
                <div
                  class="resultBtn_box"
                  v-if="item1.quetiontype==4 || item1.quetiontype==1 ||item1.quetiontype==5"
                >
                  <div class="resultBtn resultBtn1" v-if="item1.judge==0">答错了</div>
                  <div class="resultBtn" v-if="item1.judge==1">答对了</div>
                </div>
                <div class="analysis">
                  <span>解析：</span>
                  {{item1.analysis?item1.analysis:'无'}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧答题卡 -->
        <div class="answer">
          <div class="answerTitile">答题卡</div>
          <div class="answerTop">
            <div class="whether">
              <div class="whetherItem">
                <div></div>
                <div>正确</div>
              </div>
              <div class="whetherItem">
                <div></div>
                <div>未答</div>
              </div>
              <div class="whetherItem">
                <div></div>
                <div>答错</div>
              </div>
            </div>
            <div class="num">
              您共答错
              <span>{{num}}道题</span> 点击序号查看错题
            </div>
          </div>
          <div class="answerCard">
            <div class="answerList flex">
              <div
                :class="item.is_all == 0 ? 'noAnswer':item.judge==0 ?'errorAnswer':''"
                v-for="(item, index) in examList"
                :key="index"
                @click="goDingyue(item.id)"
              >{{index+1}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { analysis } from "../../assets/js/question.js";
let timer = null;
let singleAnswer = {};
export default {
  name: "analysis",
  data() {
    return {
      examId: "",
      user_id: "",
      examList: [],
      test: false,

      num: 0,
    };
  },
  mounted() {
    // console.log(this.$route.query.c_e_id);
    this.user_id = this.$route.query.user_id;
    this.examId = this.$route.query.id;
    if (!this.$route.query.id) {
      this.$router.push("/index");
      return;
    }
    window.scroll(0, 0);
    this.examData();
  },
  beforeDestroy() {},
  computed: {},
  watch: {},
  methods: {
    examData() {
      let i = 0;
      analysis({ id: this.examId, user_id: this.user_id }).then((res) => {
        this.test = true;
        this.exam = res.data.exam;
        this.examList = res.data.exam.examquestions;
        this.examList.forEach((item) => {
          if (item.judge == 0) {
            i++;
          }
        });
        this.num = i;
      });
    },
    // 返回上一页
    goback() {
      this.$router.go(-1);
    },

    // 返回上一页
    back() {
      this.$router.go(-1);
    },
    // 锚点定位
    goDingyue(id) {
      this.$el
        .querySelector(`.examList${id}`)
        .scrollIntoView({ block: "end", behavior: "smooth" });
    },
  },
};
</script>
<style lang="less" scoped>
.bgc {
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f5f5f5;
}
.w {
  margin: 0 auto;
  width: 1200px;
}
.bgc-kong {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #f5f5f5;
  z-index: 3;
}
.exam {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}
.examCont {
  width: 933px;
  background-color: #fff;
  box-sizing: border-box;
  min-height: 100vh;
  .examTop1 {
    height: 196px;
    background-color: #fff;
    z-index: 3;
  }
  .examTop {
    position: fixed;
    top: 20px;
    width: 933px;
    height: 196px;
    color: #fff;
    background-image: url("../../assets/img/tiku/bg_1_15.png");
    background-repeat: no-repeat;
    z-index: 2;
    .goBack {
      position: absolute;
      top: 20px;
      left: 30px;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .examName {
      padding-top: 40px;
      font-size: 30px;
    }
    .step {
      display: flex;
      margin: 19px 0;
      font-size: 16px;
      color: #fff;

      div {
        margin-right: 20px;
      }
    }
    .examTime {
      display: flex;
      align-items: center;
      font-size: 16px;
      div {
        width: 40px;
        height: 40px;
        margin: 0 10px;
        text-align: center;
        line-height: 40px;
        background-color: #5cbeff;
        border-radius: 5px;
      }
    }
  }
}
.examType {
  margin: 0 55px;
  margin-top: 40px;
  font-size: 18px;
  .examList {
    .topic {
      // display: flex;
      // align-items: center;
      line-height: 30px;

      .status {
        flex-shrink: 0;
        margin-right: 20px;
        width: 80px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        border-radius: 15px;
        background-color: #029aff;
      }
      .status1 {
        background-color: #ff7402;
      }
      .status2 {
        background-color: #b502ff;
      }
    }
    .option {
      margin-top: 35px;
      margin-left: 100px;
      .choose {
        display: block;
        margin-bottom: 30px;
      }
    }
    .option1 {
      margin-top: 10px !important;
      margin-left: 145px;
    }
    .topic1 {
      flex: 0;
      // margin-left: 20px;
      margin-bottom: 20px;
      // div {
      //   flex-shrink: 0;
      // }
    }
    .text {
      text-align: justify;
    }
    .filling {
      width: 100%;
      text-indent: 20px;
      margin-bottom: 30px;
      height: 40px;
      background-color: #fff;
      border: 1px solid #ccc;
    }
    .response {
      //   margin-left: 40px;
      //   width: 600px;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 30px;
      box-sizing: border-box;
      border-radius: 5px;
    }
    .contrast {
      span {
        &:first-child {
          color: #029aff;
          margin-right: 20px;
        }
      }
    }
  }
}
// 右侧答题卡
.answer {
  position: fixed;
  top: 20px;
  // right: 0;
  right: calc((100vw - 1200px) / 2);
  width: 250px;
  min-height: 80vh;
  background-color: #ffffff;
  padding-bottom: 30px;
  .answerTitile {
    text-align: center;
    line-height: 40px;
    height: 40px;
    background-color: #029aff;
    color: #ffffff;
    font-size: 18px;
  }
  .answerTop {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #eaeaea;
    text-align: center;
    .whether {
      display: flex;
      justify-content: center;
      .whetherItem {
        display: flex;
        align-items: center;
        div:first-child {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          overflow: hidden;
          background-color: #029aff;
        }
        &:first-child {
          margin-right: 10px;
        }
        &:nth-child(2) {
          margin-right: 10px;
          div:first-child {
            border: 1px solid #029aff;
            box-sizing: border-box;
            background-color: transparent;
          }
        }
        &:last-child {
          div:first-child {
            background-color: #FC2727;
            box-sizing: border-box;
          }
        }
      }
    }
    .num {
      margin-top: 5px;
      span {
        color: #ff0000;
      }
    }
  }
}
.answerCard {
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 60vh;
  .type {
    margin: 12px 0;
    padding-left: 10px;
    height: 24px;
    line-height: 24px;
    color: #029aff;
    font-size: 16px;
    border-left: 5px solid #029aff;
  }
  .answerList {
    margin: 0 30px;
    flex-wrap: wrap;
    width: 190px;
    // min-height: 60vh;
    // background-color: antiquewhite;
    div {
      cursor: pointer;
      width: 36px;
      height: 36px;
      color: #fff;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      background-color: #029aff;
      border-radius: 50%;
      margin-right: 41px;
      margin-bottom: 20px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    .noAnswer {
      color: #029aff;
      border: 1px solid #029aff;
      box-sizing: border-box;
      background-color: transparent;
    }
    .errorAnswer {
      background-color: #FC2727;
      box-sizing: border-box;
    }
  }
}
.submit {
  margin: 0 auto;
  margin-top: 30px;
  width: 140px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  background-color: #029aff;
  border-radius: 10px;
}
// 答案解析
.result {
  font-size: 18px;
  color: #343434;
  margin-bottom: 20px;
  span {
    color: #029aff;
    margin: 0 5px;
  }
  .big {
    font-size: 34px;
  }
}
.resultBtn_box {
  margin-top: 20px;
}
.resultBtn {
  width: 180px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  color: #fff;
  background: #029aff;
}
.resultBtn1 {
  color: #343434;
  background-color: #eaeaea;
}
.analysis {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 18px;
  span {
    color: #029aff;
  }
}
/deep/ .el-radio {
  color: #343434;
}
::v-deep .el-radio__label {
  width: 100% !important;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 18px;

}
::v-deep .el-checkbox__label {
  display: inline-grid;
  white-space: pre-line;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 20px;
}
/deep/ .el-radio__inner {
  cursor: default !important;
}
/deep/ .el-radio__inner::after {
  background-color: #029AFF !important;
}
/deep/ .el-radio__label {
  // cursor: pointer !important;
  color: #333 !important;
  cursor: default !important;
}
/deep/ .el-checkbox__label {
  color: #333 !important;
  cursor: default !important;
}
/deep/ .el-checkbox__inner {
  cursor: default !important;
}
/deep/ .is-checked .el-checkbox__inner {
  background-color: #029AFF !important;
  border-color: #029AFF !important;
}
/deep/ .is-checked .el-checkbox__inner::after {
  border-color: #fff !important;
}
/deep/ .el-textarea__inner:focus {
  border-color: #DCDFE6 !important;
}
/deep/ .el-textarea__inner:hover {
  border-color: #DCDFE6 !important;
}
.answerCard::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
.answerCard::-webkit-scrollbar-track {
  background: rgba(191, 191, 191, 0.4);
  border-radius: 4px;
}
.answerCard::-webkit-scrollbar-thumb {
  background: rgba(191, 191, 191, 1);
  border-radius: 4px;
}
.index {
  margin-right: 10px;
}
.warp {
  flex-shrink: 0;
}
.num {
  font-size: 12px;
}
</style>